import BarChart from '@/components/charts/bar-chart'
import DataCard from '../data-card'
import SectionCard from '../section-card'
import useLocalAppPreferences from '@/hooks/use-local-app-preferences'
import { Card, CardContent, CardHeader, CardTitle, TooltipProvider } from '@/components/ui'
import useSalesAnalytics from '../../hooks/use-sales-analytics'
import CargurusStackedPie from './cargurus-stacked-pie'
import CargurusBar from './cargurus-bar'
import CargurusTable from './cargurus-table'
import { Skeleton } from '@mui/material'
import ByAgeStackedPie from './by-age-stacked-pie'
import ByAgeTable from './by-age-table'
import BySourceTable from './by-source-table'
import config from '../sales-overview.config'
import useQueryDates from '@/hooks/use-query-dates'
import dayjs from 'dayjs'

type SalesOverviewTabProps = {
  stores: any
}

export default function SalesOverviewTab({ stores }: SalesOverviewTabProps) {
  const preferences = useLocalAppPreferences()
  const adjustments = preferences.preferences.dashboard.useAdjustments
  const { data, loading } = useSalesAnalytics(stores)
  const { startDate, endDate } = useQueryDates()
  const startDateString = dayjs(startDate).format('MMM DD')
  const endDateString = dayjs(endDate).format('MMM DD')
  return (
    <div className='flex flex-col gap-4 pb-32'>
      <SectionCard title='Sales Overview' description={`All sales data from ${startDateString} thru ${endDateString}`}>
        <TooltipProvider>
          <div className='w-full grid lg:flex grid-cols-3 gap-2 md:gap-4 p-0 pb-1'>
            {config.salesOverviewTiles.map((tile) => (
              <DataCard
                key={tile.title}
                title={tile.title}
                data={data}
                dataKey={adjustments ? tile.alternateKey ?? tile.dataKey : tile.dataKey}
                type={tile.type as 'currency' | 'number' | 'percent'}
                loading={loading}
                color={tile.color}
                comparator={tile.isComparator}
              />
            ))}
          </div>
        </TooltipProvider>
        <div className='w-full p-0 pt-4'>
          <BarChart
            data={data?.map((item) => item.SalesPerDayGraphData)}
            loading={loading}
            dataKey='key'
            valueKey='value'
            valueName='Total Sales'
            title='Sales Per Day'
            legendValues={data?.map((item) => item.Store)}
            hrefFormatter={(value: SalesPerDayData, index: number) => {
              return `/sales?startDate=${value.key}&endDate=${value.key}&tabIndex=${index}`
            }}
            tooltipValues={[
              { key: 'TotalSales', format: 'plain' },
              { key: 'DayPercentOfTotal', format: 'percent' },
              { key: 'TotalGross', format: 'currency' },
              { key: 'AverageGrossPerSale', format: 'currency' },
              { key: 'Rollbacks', format: 'plain' },
              { key: 'TotalAdjustments', format: 'currency' }
            ]}
          />
        </div>
      </SectionCard>
      <SectionCard title='Cargurus at a glance' description='Cargurus pricing data for all stores' className='relative'>
        <div className='w-full flex flex-col md:flex-row space-evenly gap-4'>
          <CargurusStackedPie
            title='Sales'
            data={data}
            loading={loading}
            legendAlign={data?.length && data.length > 1 ? 'center' : 'left'}
            legendPosition={data?.length && data.length > 1 ? 'bottom' : 'middle'}
          />
          <CargurusTable data={data} loading={loading} />
        </div>
        {data?.map((entry, index) => (
          <Card key={index} className='mt-2'>
            <CardHeader>
              <CardTitle>
                {loading ? (
                  <Skeleton width='40%' height={20} className='inline-block' />
                ) : (
                  `Cargurus pricing for ${entry.Store}`
                )}
              </CardTitle>
            </CardHeader>
            <CardContent>
              <CargurusBar
                data={entry?.CargurusRating}
                carGurusPreference={entry?.CargurusPreference}
                loading={loading || stores.loading}
              />
            </CardContent>
          </Card>
        ))}
      </SectionCard>
      <SectionCard title='Sales by age' description='Sales data by age of vehicle'>
        <div className='w-full flex flex-col md:flex-row space-evenly gap-4'>
          <ByAgeStackedPie
            title='Sales'
            data={data}
            loading={loading}
            legendAlign={data?.length && data.length > 1 ? 'center' : 'left'}
            legendPosition={data?.length && data.length > 1 ? 'bottom' : 'middle'}
          />
          <ByAgeTable data={data} loading={loading} />
        </div>
      </SectionCard>
      <SectionCard title='Sales by source' description='Sales data by source of vehicle'>
        <BySourceTable data={data} loading={loading} />
      </SectionCard>
    </div>
  )
}
