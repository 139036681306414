import {
  Badge,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from '@/components/ui'
import { cn } from '@/lib/utils'
import { Skeleton } from '@mui/material'
import dayjs from 'dayjs'
import { AnimatePresence, motion } from 'framer-motion'
import { TrendingDownIcon, TrendingUpIcon } from 'lucide-react'

interface DataCardProps {
  title: string
  data: any[] | undefined
  dataKey: string
  type: 'currency' | 'number' | 'percent'
  loading?: boolean
  className?: string
  color?: string
  comparator?: boolean
  explanation?: string
}

export default function DataCard({
  title,
  data,
  dataKey,
  type,
  loading,
  className,
  color,
  comparator,
  explanation
}: DataCardProps) {
  const colorString = `bg-${color}`
  return (
    <Card className={cn('w-full md:min-w-32', className)}>
      <CardHeader className='text-sm p-2 md:p-4 md:pb-2 space-y-0'>
        <CardTitle className='font-normal whitespace-nowrap flex items-center gap-1'>
          {title} {color && <div className={cn('w-1.5 h-1.5 rounded-full', colorString)}></div>}
          {explanation && (
            <Tooltip delayDuration={0}>
              <TooltipTrigger>
                <div className='rounded-full aspect-square border px-1 text-[0.65rem] border-black/50 opacity-60 flex items-center'>
                  ?
                </div>
              </TooltipTrigger>
              <TooltipContent side='bottom'>{explanation}</TooltipContent>
            </Tooltip>
          )}
        </CardTitle>
      </CardHeader>
      <CardContent className='text-base font-bold p-2 md:p-4 md:pb-2 pt-0 md:pt-0'>
        <AnimatePresence mode='popLayout'>
          {loading === true || !data ? (
            <motion.div
              key='data-loading'
              initial={{ width: '0%', paddingLeft: 0 }}
              animate={{ width: '100%', paddingLeft: 0, opacity: 1 }}
              exit={{ width: '0%', paddingLeft: '100%', opacity: 0, transition: { duration: 0.3 } }}
              className='bg-background'
              transition={{ duration: 0.1, type: 'tween', ease: 'easeInOut' }}
            >
              <Skeleton variant='text' width='100%' height={16} />
              <Skeleton variant='text' width='100%' height={24} />
            </motion.div>
          ) : (
            <div key={`data-loaded__${Boolean(loading).toString()}`} className='flex flex-col gap-2'>
              {data?.map((item) => {
                let value = item[dataKey]
                if (value === undefined) value = 0
                if (value === null) value = 0
                const formattedValue =
                  type === 'currency' ? `$${value.toLocaleString()}` : type === 'percent' ? `${value}%` : String(value)
                let formattedComparison
                if (comparator) {
                  // @ts-ignore
                  formattedComparison = item.comparators?.[dataKey]
                  if (formattedComparison === undefined) formattedComparison = 0
                  if (formattedComparison === null) formattedComparison = 0
                  formattedComparison = Math.round(formattedComparison)
                  formattedComparison =
                    type === 'currency'
                      ? `$${formattedComparison.toLocaleString()}`
                      : type === 'percent'
                      ? `${formattedComparison}%`
                      : String(formattedComparison)
                }
                return (
                  <div className='flex flex-col gap-0' key={item.Store}>
                    <span className='text-xs font-normal opacity-60'>{item.Store}</span>
                    <span className='text-base font-bold flex items-center gap-2'>
                      {formattedValue}{' '}
                      {comparator && (
                        <Tooltip delayDuration={0}>
                          <TooltipTrigger>
                            <Badge
                              className={cn(
                                'w-6 px-1 py-0 h-4 flex items-center hover:bg-transparent bg-transparent shadow-none',
                                // @ts-ignore
                                item.comparators?.[dataKey] < value && 'text-green-700',
                                // @ts-ignore
                                item.comparators?.[dataKey] > value && 'text-red-500',
                                // @ts-ignore
                                item.comparators?.[dataKey] === value && 'text-yellow-500'
                              )}
                            >
                              {
                                // @ts-ignore
                                item.comparators?.[dataKey] < value ? (
                                  <TrendingUpIcon />
                                ) : // @ts-ignore
                                item.comparators?.[dataKey] > value ? (
                                  <TrendingDownIcon />
                                ) : (
                                  '-'
                                )
                              }
                            </Badge>
                          </TooltipTrigger>
                          <TooltipContent side='bottom' className='text-[0.75rem] px-2 py-0'>
                            Was{' '}
                            {
                              // @ts-ignore
                              formattedComparison
                            }{' '}
                            in the period of {dayjs(item.PreviousAdjacentStartDate).format('MMM D')} to{' '}
                            {dayjs(item.PreviousAdjacentEndDate).format('MMM D')}
                          </TooltipContent>
                        </Tooltip>
                      )}
                    </span>
                  </div>
                )
              })}
            </div>
          )}
        </AnimatePresence>
      </CardContent>
    </Card>
  )
}
