import { useSearchParams } from 'react-router-dom'
import useLocalAppPreferences from './use-local-app-preferences'

export default function useAppliedRedirectFilters() {
  const [urlParams, setParams] = useSearchParams()
  const preferences = useLocalAppPreferences()
  const appliedFilters = urlParams.get('redirectFilters')
  if (!appliedFilters) return null
  urlParams.delete('redirectFilters')
  try {
    console.log(appliedFilters)
    const filtersObj = JSON.parse(appliedFilters)
    preferences.setPreference('lists.vehicles.filters', filtersObj)
    setParams(urlParams, { replace: true })
  } catch (e) {
    console.error('Error parsing redirect filters', e)
  }
}
