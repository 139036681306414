import { v2 as axios } from '@/lib/axios'
import { SalesOverviewData } from './sales-overview'
import { InventoryOverviewData } from './inventory-overview'
import { OverviewData } from './overview'
import { InventoryFootprintData } from './inventory-footprint'

export const getSalesOverview = async (
  companyId: number,
  options?: { startDate?: string; endDate?: string; excludeSources?: number[] }
) => {
  const { data } = await axios.get(`/companies/${companyId}/analytics/sales-overview`, {
    params: {
      startDate: options?.startDate,
      endDate: options?.endDate,
      excludeSources: options?.excludeSources?.join(',') ?? null
    }
  })
  return data as SalesOverviewData
}

export const getInventoryOverview = async (companyId: number, options?: { excludeSources?: number[] }) => {
  const { data } = await axios.get(`/companies/${companyId}/analytics/inventory-overview`, {
    params: {
      excludeSources: options?.excludeSources?.join(',') ?? null
    }
  })
  return data as InventoryOverviewData
}

export const getOverview = async (
  companyId: number,
  options?: { startDate?: string; endDate?: string; excludeSources?: number[] }
) => {
  const { data } = await axios.get(`/companies/${companyId}/analytics/overview`, {
    params: {
      startDate: options?.startDate,
      endDate: options?.endDate,
      excludeSources: options?.excludeSources?.join(',') ?? null
    }
  })
  return data as OverviewData
}

export const getInventoryFootprint = async (companyId: number, options?: { timeSpan?: string }) => {
  const { data } = await axios.get(
    `/companies/${companyId}/analytics/footprint?timeSpan=${options?.timeSpan ?? 'sixMonths'}`
  )
  return data as InventoryFootprintData
}
