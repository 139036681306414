import useStores from '@/hooks/use-stores'
import useReportHistory from './hooks/use-report-history'
import { Badge, Button, Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui'
import dayjs from 'dayjs'
import { proper } from '@/lib/utils'
import { useEffect, useState } from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro'
import DetailPanelContent from './components/detail-panel-content'

export default function Reports() {
  const stores = useStores()
  const tabIndex = Number(new URLSearchParams(location.search).get('tabIndex') ?? 0)
  const defaultTab = String(stores.data.filter((store) => store.IsSelected)[tabIndex]?.CompanyId ?? 0)
  const [tab, setTab] = useState(defaultTab)
  const { data, loading, create } = useReportHistory(
    Number(tab) ? stores.data.find((store) => store.CompanyId === Number(tab)) : undefined
  )

  useEffect(() => {
    setTab(defaultTab)
  }, [defaultTab])

  return (
    <div className='flex flex-col items-end w-full h-full overflow-y-hidden pb-28 md:pb-9'>
      <Tabs value={tab} onValueChange={setTab} className='w-full h-full'>
        <TabsList className='rounded-b-none w-full justify-start'>
          {stores.data
            .filter((store) => store.IsSelected)
            .map((store) => (
              <TabsTrigger key={store.CompanyId} value={String(store.CompanyId)}>
                {store.Name}
              </TabsTrigger>
            ))}
        </TabsList>
        {stores.data
          .filter((store) => store.IsSelected)
          .map((store) => (
            <TabsContent
              key={store.CompanyId}
              className='w-full overflow-y-auto h-full mt-0'
              value={String(store.CompanyId)}
            >
              <div className='h-full pb-9'>
                <DataGridPro
                  columns={[
                    { field: 'ReportHistoryId', headerName: 'Report ID', width: 80 },
                    { field: 'SendDate', headerName: 'Send Date', width: 150 },
                    { field: 'Status', headerName: 'Status', width: 100 },
                    {
                      field: 'ReceivingEmailAddresses',
                      headerName: 'Receiving Email Addresses',
                      flex: 1,
                      renderCell: (params) => (
                        <div className='flex gap-1 items-center h-full w-full flex-wrap'>
                          {params.value.length > 0 &&
                            params.value.split(',').map((email: string) => (
                              <Badge variant='secondary' className='rounded-full border border-black/20'>
                                <span>{email}</span>
                              </Badge>
                            ))}
                        </div>
                      )
                    }
                  ]}
                  disableRowSelectionOnClick
                  getRowId={(row) => row.ReportHistoryId}
                  getRowClassName={(params) => {
                    if (params.row.Status === 'Success') return 'bg-green-50'
                    if (params.row.Status === 'Failed') return 'bg-red-50'
                    if (params.row.Status === 'Cancelled') return 'bg-yellow-50'
                    return 'bg-yellow-50'
                  }}
                  getDetailPanelHeight={() => 768}
                  getDetailPanelContent={(params) => <DetailPanelContent row={params.row as ReportHistory} />}
                  rows={
                    data
                      ?.map((row) => ({
                        ...row,
                        SendDate: row.SendDate ? dayjs(row.SendDate).format('MMM D, YYYY') : 'Not Sent',
                        Status: proper(row.Status)
                      }))
                      .sort((a, b) => {
                        return b.ReportHistoryId - a.ReportHistoryId
                      }) ?? []
                  }
                  loading={loading}
                  density='comfortable'
                  slots={{
                    toolbar: () => (
                      <div className='p-2'>
                        <Button size='sm' onClick={create as () => void} loading={loading}>
                          Generate new report
                        </Button>
                      </div>
                    )
                  }}
                />
              </div>
            </TabsContent>
          ))}
      </Tabs>
    </div>
  )
}
