import LinkWithQuery from './link-with-query'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './ui'

type StructuredTableProps<T> = {
  data: T[] | undefined
  columns: Column<T>[]
}

type Column<T> = {
  title: string
  onClick?: (data: T) => void
  getHref?: (data: T) => {
    pathname: string
    query: Record<string, string>
  }
  getTooltip?: (data: T) => React.ReactNode
  render: (data: T) => React.ReactNode | string
}

export default function StructuredTable<T>({ data, columns }: StructuredTableProps<T>) {
  console.log(data)
  return (
    <Table>
      <TableHeader>
        <TableRow>
          {columns.map((column) => (
            <TableHead key={column.title} className='text-left'>
              {column.title}
            </TableHead>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {Array.isArray(data) &&
          data.map((row, i) => (
            <TableRow key={i}>
              {columns.map((column) => (
                <TableCell key={column.title} className='text-left' onClick={() => column.onClick?.(row)}>
                  {column.getHref ? (
                    <LinkWithQuery to={column.getHref(row).pathname} query={column.getHref(row).query}>
                      {column.render(row)}
                    </LinkWithQuery>
                  ) : (
                    column.render(row)
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  )
}
