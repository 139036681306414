import LinkWithQuery from '@/components/link-with-query'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui'
import useLocalAppPreferences from '@/hooks/use-local-app-preferences'
import { InventoryOverviewData } from '@/services/api/v2/companies/[companyId]/analytics/inventory-overview'
import { Skeleton } from '@mui/material'

type ByAgeTableProps = {
  data: InventoryOverviewData[] | undefined
  loading: boolean
}

export default function ByAgeTable({ data, loading }: ByAgeTableProps) {
  const preferences = useLocalAppPreferences()
  return (
    <Table className='whitespace-nowrap'>
      <TableHeader>
        <TableRow>
          <TableHead className='w-14'>Rating</TableHead>
          <TableHead className='w-1/4'>Store</TableHead>
          <TableHead className='w-1/4'>Total units</TableHead>
          <TableHead className='w-1/4'>Avg investment</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data?.[0]?.InventoryByAgeGraphData.map((listItem, index) => (
          <TableRow>
            {loading ? (
              <TableCell className='w-14'>
                <div className='flex items-center'>
                  <div className='w-2 h-2 rounded-full inline-block mr-1 -translate-y-[0.05rem] bg-stone-400 animate-pulse'></div>
                  <Skeleton width='80%' height={20} className='inline-block' />
                </div>
              </TableCell>
            ) : (
              <TableCell className='w-14'>
                <LinkWithQuery
                  to='/sales'
                  onClick={() => preferences.setPreference('lists.vehicles.filters', listItem.appliedFilters)}
                  className='hover:underline'
                >
                  <div
                    className='w-2 h-2 rounded-full inline-block mr-1 -translate-y-[0.05rem]'
                    style={{ backgroundColor: listItem.Color }}
                  ></div>
                  <span>{listItem.label} days</span>
                </LinkWithQuery>
              </TableCell>
            )}
            <TableCell colSpan={4} className='w-3/4'>
              <Table className='-ml-2'>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell className='w-full'>
                        <Skeleton width='100%' height={20} />
                      </TableCell>
                    </TableRow>
                  ) : (
                    data?.map((item: InventoryOverviewData, storeIndex) => (
                      <TableRow key={`name-${item.Store}`}>
                        <TableCell className='block text-ellipsis overflow-x-hidden w-11/12'>
                          <LinkWithQuery
                            to='/sales'
                            query={{ tabIndex: String(storeIndex) }}
                            onClick={() => preferences.setPreference('lists.vehicles.filters', listItem.appliedFilters)}
                            className='hover:underline'
                          >
                            <span>{item.Store}</span>
                          </LinkWithQuery>
                        </TableCell>
                        <TableCell className='w-1/3'>
                          <span>
                            {item.InventoryByAgeGraphData[index]?.Count} (
                            {item.InventoryByAgeGraphData[index]?.PercentOfTotal}
                            %)
                          </span>
                        </TableCell>
                        <TableCell className='w-1/3'>
                          <span>${item.InventoryByAgeGraphData[index]?.AverageInvestment?.toLocaleString()}</span>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
