import useQueryDates from '@/hooks/use-query-dates'
import { v2 as axios } from '@/lib/axios'
import { getReportHistories } from '@/services/api/v2/companies/[companyId]/reports/report-history'
import { useMutation, useQuery } from '@tanstack/react-query'

export default function useReportHistory(store: Company | undefined) {
  const { startDate, endDate } = useQueryDates()
  const {
    data,
    isPending: loading,
    refetch
  } = useQuery({
    queryKey: ['report-history', store?.CompanyId],
    queryFn: () => {
      if (store) {
        return getReportHistories(store.CompanyId)
      }
    },
    enabled: !!store
  })

  const { mutateAsync: create, isPending: adding } = useMutation({
    mutationFn: async () => {
      if (store) {
        const { data } = await axios.get(`companies/${store.CompanyId}/reports/general`, {
          params: {
            startDate,
            endDate
          }
        })
        return data
      }
      return data
    },
    onSuccess: refetch
  })

  return {
    data,
    loading: loading || adding,
    create
  }
}
