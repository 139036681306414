const salesOverviewTiles = [
  {
    title: 'Total sales',
    dataKey: 'TotalSales',
    type: 'number',
    color: 'primary'
  },
  {
    title: 'Average PVR',
    dataKey: 'AverageGrossPerSale',
    type: 'currency'
  },
  {
    title: 'Sales pace',
    dataKey: 'SalesPace',
    type: 'number'
  }
]

const inventoryOverviewTiles = [
  {
    title: 'Total inventory',
    dataKey: 'TotalInventory',
    type: 'number',
    color: 'primary'
  },
  {
    title: 'Total investment',
    dataKey: 'TotalInvestment',
    type: 'currency'
  },
  {
    title: 'Days supply',
    dataKey: 'DaysSupply',
    type: 'number'
  }
]

export default {
  salesOverviewTiles,
  inventoryOverviewTiles
}
