import DataCard from './data-card'
import SectionCard from './section-card'
import { Badge, TabsContent, TooltipProvider } from '@/components/ui'
import useOverviewAnalytics from '../hooks/use-overview-analytics'
import useLocalAppPreferences from '@/hooks/use-local-app-preferences'
import useStores from '@/hooks/use-stores'
import StructuredTable from '@/components/structured-table'
import StoreTabs from '@/components/store-tabs'
import config from './overview.config'

type OverviewTabProps = {
  stores: ReturnType<typeof useStores>
}

export default function OverviewTab({ stores }: OverviewTabProps) {
  const { data, loading } = useOverviewAnalytics(stores)
  const preferences = useLocalAppPreferences()
  const setTab = (tab: string) => preferences.setPreference('dashboard.defaultTab', tab)
  return (
    <div className='flex flex-col gap-4 pb-32'>
      <TooltipProvider>
        <div className='flex gap-4 items-center'>
          <SectionCard
            title='Sales overview'
            description={
              <div onClick={() => setTab('sales-breakdown')} className='cursor-pointer text-primary'>
                View detailed sales data
              </div>
            }
          >
            <div className='w-full grid lg:flex grid-cols-3 gap-2 md:gap-4 p-0 pb-1'>
              {config.salesOverviewTiles.map((tile) => (
                <DataCard
                  key={tile.title}
                  title={tile.title}
                  data={data}
                  dataKey={tile.dataKey}
                  type={tile.type as 'currency' | 'number' | 'percent'}
                  loading={loading}
                  color={tile.color}
                />
              ))}
            </div>
          </SectionCard>
          <SectionCard
            title='Inventory overview'
            description={
              <div onClick={() => setTab('inventory-breakdown')} className='cursor-pointer text-primary'>
                View detailed inventory data
              </div>
            }
          >
            <div className='w-full grid lg:flex grid-cols-3 gap-2 md:gap-4 p-0 pb-1'>
              {config.inventoryOverviewTiles.map((tile) => (
                <DataCard
                  key={tile.title}
                  title={tile.title}
                  data={data}
                  dataKey={tile.dataKey}
                  type={tile.type as 'currency' | 'number' | 'percent'}
                  loading={loading}
                  color={tile.color}
                />
              ))}
            </div>
          </SectionCard>
        </div>
        <SectionCard
          title='Model stocking'
          description={
            <div onClick={() => setTab('inventory-footprint')} className='cursor-pointer text-primary'>
              View more detailed model stocking data
            </div>
          }
        >
          <StoreTabs>
            {stores?.data
              .filter((e) => e.IsSelected)
              .map((_, index) => (
                <TabsContent value={String(index)} key={index}>
                  <div className='flex gap-4 items-center'>
                    <div className='w-full h-72 overflow-auto shadow rounded-lg p-2'>
                      <div className='w-full text-center font-bold opacity-30'>Overstocked</div>
                      <StructuredTable
                        data={data?.[index]?.ActionItems.HighUnitCountForModel}
                        columns={[
                          {
                            title: 'Model',
                            render: (data: any) => data?.data?.name
                          },
                          {
                            title: 'Count in inventory',
                            render: (data: any) => data?.data?.unitsInStock
                          },
                          {
                            title: 'Offset',
                            render: (data: any) => `${Math.abs(data?.data?.amountOffset)} too many`
                          }
                        ]}
                      />
                    </div>
                    <div className='w-full h-72 overflow-auto shadow rounded-lg p-2'>
                      <div className='w-full text-center font-bold opacity-30'>Understocked</div>
                      <StructuredTable
                        data={data?.[index]?.ActionItems.LowUnitCountForModel}
                        columns={[
                          {
                            title: 'Model',
                            render: (data: any) => data?.data?.name
                          },
                          {
                            title: 'Count in inventory',
                            render: (data: any) => data?.data?.unitsInStock
                          },
                          {
                            title: 'Offset',
                            render: (data: any) => `${Math.abs(data?.data?.amountOffset)} too few`
                          }
                        ]}
                      />
                    </div>
                  </div>
                </TabsContent>
              ))}
          </StoreTabs>
        </SectionCard>
        <SectionCard title='Underperforming sources' description='Sources which may need attention'>
          <StoreTabs>
            {stores?.data
              .filter((e) => e.IsSelected)
              .map((_, index) => (
                <TabsContent value={String(index)} key={index}>
                  <div className='w-full h-72 overflow-auto shadow rounded-lg p-2'>
                    <StructuredTable
                      data={data?.[index]?.SourceStockingData}
                      columns={[
                        {
                          title: 'Source',
                          render: (data: any) => data?.Source
                        },
                        {
                          title: 'Low performance reasons',
                          render: (data: any) =>
                            data?.Reasons?.map((reason: string) => (
                              <Badge key={reason} variant='secondary' className='mr-2'>
                                {reason}
                              </Badge>
                            ))
                        }
                      ]}
                    />
                  </div>
                </TabsContent>
              ))}
          </StoreTabs>
        </SectionCard>
      </TooltipProvider>
    </div>
  )
}
