import React from 'react'
import { Routes, Navigate, Route } from 'react-router-dom'
import { RouteDefinition } from './routing'
import { routes } from './routes'

export default function RouteDefinitions() {
  document.title =
    'Max Autolytics | ' +
      routes.find((route) => route.path === window.location.pathname)?.properties.navigation.title || 'Main'

  return (
    <Routes>
      {routes.map((route: RouteDefinition) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <RouteElement authType={route.properties.authentication}>
              <route.component />
            </RouteElement>
          }
        />
      ))}
    </Routes>
  )
}

type RouteElementProps = {
  authType: 'public' | 'private'
  children: React.ReactNode
}

function RouteElement({ authType, children }: RouteElementProps) {
  const requiresAuth = authType === 'private'
  return requiresAuth ? <ProtectedRoute>{children}</ProtectedRoute> : children
}

function ProtectedRoute({ children }: Omit<RouteElementProps, 'authType'>) {
  const token = localStorage.getItem('Authorization')
  const isAuthenticated = token ? true : false

  if (isAuthenticated) return children
  const redirect = window.location.pathname
  return <Navigate to={`/login?redirect=${redirect}`} />
}
