import { v2 as axios } from '@/lib/axios'

export const getReportHistories = async (companyId: number, options?: { status?: 'pending' | 'success' | 'failed' | 'cancelled' }) => {
  const { data } = await axios.get(`/companies/${companyId}/reports/report-history`, {
    params: {
      status: options?.status
    }
  })
  return data as ReportHistory[]
}

export const getReportHistory = async (companyId: number, reportHistoryId: number) => {
  const { data } = await axios.get(`/companies/${companyId}/reports/report-history/${reportHistoryId}`)
  return data as ReportHistory
}

export const createReportHistory = async (companyId: number, report: CreateReportHistoryDto) => {
  const { data } = await axios.post(`/companies/${companyId}/reports/report-history`, report)
  return data as ReportHistory
}

export const updateReportHistory = async (companyId: number, reportHistoryId: number, report: UpdateReportHistoryDto) => {
  const { data } = await axios.patch(`/companies/${companyId}/reports/report-history/${reportHistoryId}`, report)
  return data as ReportHistory
}

export const deleteReportHistory = async (companyId: number, reportHistoryId: number) => {
  await axios.delete(`/companies/${companyId}/reports/report-history/${reportHistoryId}`)
}
