import { DataGridPro, GridToolbarQuickFilter } from '@mui/x-data-grid-pro'
import useInventoryFootprint from '../hooks/use-inventory-footprint'
import { Badge, Button, Input, Label, Tabs, TabsList, TabsTrigger, TooltipProvider } from '@/components/ui'
import { initialState, columns } from './inventory-footprint.config'
import axios from '@/lib/axios'

type InventoryFootprintTabProps = {
  store: Company | undefined
}

export default function InventoryFootprintTab({ store }: InventoryFootprintTabProps) {
  const preferences = useLocalAppPreferences()
  const displayMode = preferences.preferences.dashboard.inventoryFootprint?.displayMode ?? 'byModel'
  const timeSpan = preferences.preferences.dashboard['inventoryFootprint']?.timeSpan ?? 'sixMonths'
  const { data, loading } = useInventoryFootprint(store, { timeSpan })
  const dataToShow = displayMode === 'byModel' ? data?.modelData : data?.sourceData
  const setTimeSpan = (timeSpan: 'sixMonths' | 'year' | 'week' | 'quarter' | 'twoWeeks') => {
    preferences.setPreference('dashboard.inventoryFootprint.timeSpan', timeSpan)
  }
  const setDisplayMode = (displayMode: 'byModel' | 'bySource') => {
    preferences.setPreference('dashboard.inventoryFootprint.displayMode', displayMode)
  }
  return (
    <div className='w-full flex flex-col absolute inset-0 top-6 mt-3'>
      <TooltipProvider>
        <DataGridPro
          initialState={initialState}
          columns={columns}
          rows={dataToShow ?? []}
          loading={loading}
          getRowId={(row) => row.name}
          disableRowSelectionOnClick
          slots={{
            toolbar: () => (
              <Header
                timeSpan={timeSpan}
                setTimeSpan={setTimeSpan}
                showByType={displayMode}
                setShowByType={setDisplayMode}
              />
            ),
            detailPanelExpandIcon: () => (
              <div className='flex items-center h-full w-full justify-center'>
                <Badge variant='outline'>Show more details</Badge>
              </div>
            ),
            detailPanelCollapseIcon: () => (
              <div className='flex items-center h-full w-full justify-center'>
                <Badge variant='default'>Hide details</Badge>
              </div>
            )
          }}
          getDetailPanelContent={(params) => {
            return <FootprintTableExpandedRow item={params.row} CompanyId={store?.CompanyId} />
          }}
          getDetailPanelHeight={() => 'auto'}
          getRowHeight={() => 'auto'}
        />
      </TooltipProvider>
    </div>
  )
}

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import useLocalAppPreferences from '@/hooks/use-local-app-preferences'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

type FootprintTableExpandedRowProps = {
  item: any
  CompanyId: number | undefined
}

function FootprintTableExpandedRow({ item, CompanyId }: FootprintTableExpandedRowProps) {
  const getVelocity = (item: any) => {
    if (item.averageSoldPerDay >= 1) {
      return `~${item.averageSoldPerDay} units per day.`
    } else {
      return `1 unit every ~${Math.round(1 / item.averageSoldPerDay)} days.`
    }
  }

  const getOptimalUnitCount = (item: any) => {
    const lowerBound = Math.max(0, Math.round(item.targetAmount - Math.abs(item.confidenceMargin)))
    const upperBound = Math.max(1, Math.round(item.targetAmount + Math.abs(item.confidenceMargin)))
    return Math.round(item.confidenceMargin) === 0
      ? Math.max(1, Math.round(item.targetAmount))
      : `Between ${lowerBound} and ${upperBound}`
  }

  const queryClient = useQueryClient()
  const { data: footprintPreference } = useQuery({
    queryKey: ['footprint-preferences', CompanyId],
    queryFn: async () => {
      const { data } = await axios.get(`/inventory-footprint-preference/${CompanyId}`)
      return data[0]
    }
  })

  const { data: itemPreference, isPending } = useQuery({
    queryKey: ['maker-model-preferences', item.name],
    queryFn: async () => {
      const { data } = await axios.get(`/maker-model-preference/all/${CompanyId}`)
      const newItem = data.find((e: any) => e.Model.toUpperCase() === item.model.toUpperCase())
      return newItem ?? null
    }
  })

  const { mutateAsync: updatePreference, isPending: updatePending } = useMutation({
    mutationFn: async (data: any) => {
      await axios.patch(`/maker-model-preference/${itemPreference.MakerModelPreferenceId}`, data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['footprint-preferences']
      })
      queryClient.invalidateQueries({
        queryKey: ['inventory-footprint']
      })
    }
  })

  const { mutateAsync: addPreference, isPending: addPending } = useMutation({
    mutationFn: async (data: any) => {
      await axios.post('/maker-model-preference', data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['footprint-preferences']
      })
      queryClient.invalidateQueries({
        queryKey: ['inventory-footprint']
      })
    }
  })

  const onSubmit = async (form: React.FormEvent<HTMLFormElement>) => {
    form.preventDefault()
    const formData = new FormData(form.currentTarget)
    const data = {
      TargetDaysSupply: formData.get('TargetDaysSupply')
    }
    if (itemPreference) {
      await updatePreference(data)
    } else {
      await addPreference({
        ...data,
        Model: item.model,
        InventoryFootprintPreferenceId: footprintPreference?.InventoryFootprintPreferenceId
      })
    }
  }
  return (
    <div className='grid grid-cols-3 gap-2 max-w-7xl w-full mx-auto py-12'>
      <div className='col-span-1'></div>
      <form className='flex flex-col gap-1 col-span-2' onSubmit={onSubmit}>
        <Label>
          {itemPreference ? 'Edit' : 'Add preference for'} {item.model} | Target days supply
        </Label>
        <div className='flex items-center gap-2 whitespace-nowrap'>
          <Input
            key={isPending ? 'loading' : 'loaded'}
            type='number'
            placeholder='15'
            required
            name='TargetDaysSupply'
            defaultValue={itemPreference?.TargetDaysSupply ?? 15}
            className='w-3/4'
          />
          <Button variant='default' className='w-1/4' loading={updatePending || isPending || addPending}>
            {itemPreference ? 'Save changes and refresh data' : 'Add preference and refresh data'}
          </Button>
        </div>
      </form>
      <Card>
        <CardHeader>
          <CardTitle>Velocity</CardTitle>
        </CardHeader>
        <CardContent className='flex flex-col gap-2'>
          <span className='text-base'>{getVelocity(item)}</span>
          <span className='text-xs opacity-60'>
            {item?.name ? `${item.name}'s` : 'These'} typically sell around {getVelocity(item)}
          </span>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>Days Supply</CardTitle>
        </CardHeader>
        <CardContent className='flex flex-col gap-2'>
          <span className='text-base'>{item.daysSupply} days</span>
          <span className='text-xs opacity-60'>
            You have a {item.daysSupply} days supply of {item?.name ? `${item.name}'s` : 'this model'}
          </span>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>Units in stock</CardTitle>
        </CardHeader>
        <CardContent className='flex flex-col gap-2'>
          <span className='text-base'>{item.unitsInStock} units</span>
          <span className='text-xs opacity-60'>You currently have {item.unitsInStock} units in stock</span>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>Optimal unit count</CardTitle>
        </CardHeader>
        <CardContent className='flex flex-col gap-2'>
          <span className='text-base'>{getOptimalUnitCount(item)} units</span>
          <span className='text-xs opacity-60'>
            To reach your target days supply, you should have {getOptimalUnitCount(item)} units
          </span>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>Investment potential</CardTitle>
        </CardHeader>
        <CardContent className='flex flex-col gap-2'>
          <span className='text-base'>
            {item.amountOffset * item.averageInvestment < 0 ? 'Spend' : 'Regain'} $
            {Math.round(Math.abs(item.amountOffset * item.averageInvestment)).toLocaleString()}
          </span>
          <span className='text-xs opacity-60'>
            These changes will {item.amountOffset < 0 ? 'increase' : 'decrease'} your investment by $
            {Math.round(Math.abs(item.amountOffset * item.averageInvestment)).toLocaleString()}
          </span>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>Revenue potential</CardTitle>
        </CardHeader>
        <CardContent className='flex flex-col gap-2'>
          <span className='text-base'>
            {-item.amountOffset * item.avgGross < 0 ? 'Miss out on' : 'Gain'} $
            {Math.round(Math.abs(item.amountOffset * item.avgGross)).toLocaleString()}
          </span>
          <span className='text-xs opacity-60'>
            This will {item.amountOffset < 0 ? 'increase' : 'decrease'} your revenue by $
            {Math.round(Math.abs(item.amountOffset * item.avgGross)).toLocaleString()}
          </span>
        </CardContent>
      </Card>
    </div>
  )
}

type HeaderProps = {
  timeSpan: 'sixMonths' | 'year' | 'week' | 'quarter' | 'twoWeeks'
  setTimeSpan: (timeSpan: 'sixMonths' | 'year' | 'week' | 'quarter' | 'twoWeeks') => void
  showByType: 'byModel' | 'bySource'
  setShowByType: (showByType: 'byModel' | 'bySource') => void
}
function Header({ timeSpan, setTimeSpan, showByType, setShowByType }: HeaderProps) {
  return (
    <div className='flex justify-start items-center p-4 w-96 gap-4'>
      <GridToolbarQuickFilter
        style={{
          width: '100%',
          paddingLeft: '1rem',
          paddingTop: 4,
          marginRight: 8,
          minWidth: '24rem'
        }}
        sx={{
          '& .MuiInputBase-root': {
            borderRadius: '6px',
            margin: 0,
            height: 36
          },
          '& .MuiInputBase-input': {
            border: 'none',
            boxShadow: 'none'
          },
          '& .MuiInputBase-input:focus': {
            boxShadow: 'none'
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgb(226, 232, 240)',
            boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)'
          }
        }}
        size='small'
        variant='outlined'
      />
      <Select
        value={timeSpan}
        onValueChange={(e) => setTimeSpan(e as 'sixMonths' | 'year' | 'week' | 'quarter' | 'twoWeeks')}
      >
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value={'week'}>Using sales from the last week</SelectItem>
          <SelectItem value={'twoWeeks'}>Using sales from the last 2 weeks</SelectItem>
          <SelectItem value={'quarter'}>Using sales from the last quarter</SelectItem>
          <SelectItem value={'sixMonths'}>Using sales from the last 6 months</SelectItem>
          <SelectItem value={'year'}>Using sales from the last year</SelectItem>
        </SelectContent>
      </Select>

      <Tabs value={String(showByType)} onValueChange={(newValue) => setShowByType(newValue as 'byModel' | 'bySource')}>
        <TabsList>
          <TabsTrigger value={'byModel'}>By model</TabsTrigger>
          <TabsTrigger value={'bySource'}>By source</TabsTrigger>
        </TabsList>
      </Tabs>
    </div>
  )
}
