import { StoreContext } from '@/services/providers/store-context-provider'
import { useSales } from '../hooks/use-sales'
import useQueryDates from '@/hooks/use-query-dates'
import { DataGridPro, gridFilteredSortedRowEntriesSelector, useGridApiContext } from '@mui/x-data-grid-pro'
import useDataGridProperties from '../hooks/use-data-grid-properties'
import SalesTableHeader from './sales-table-header'
import { memo, useMemo } from 'react'
import useLocalAppPreferences from '@/hooks/use-local-app-preferences'
import { Badge, Separator, TooltipProvider } from '@/components/ui'
import useAppliedRedirectFilters from '@/hooks/useAppliedRedirectFilters'

type SalesTableProps = {
  store: StoreContext
}

export default function SalesTable({ store }: SalesTableProps) {
  const { startDate, endDate } = useQueryDates()
  const { getDataGridColumns, getDataGridRows, getDataGridRowClassnames, getDataGridCellClassnames } =
    useDataGridProperties()
  const sales = useSales(store.CompanyId, startDate, endDate)
  const preferences = useLocalAppPreferences()
  useAppliedRedirectFilters()

  const filteredDocuments = useMemo(
    () => filterDocuments(sales.data),
    [sales.data, preferences.preferences.lists.vehicles.filters]
  )

  function filterDocuments(documents: SaleVehicle[] | Adjustment[]) {
    const vehicleFilters: AppPreferences['lists']['vehicles']['filters'] =
      preferences.preferences.lists.vehicles.filters
    if (!vehicleFilters) return documents
    if (vehicleFilters.length === 0) return documents
    if (!documents) return []

    return documents.filter((document) => {
      const vehicle = document as SaleVehicle
      return vehicleFilters.every((filter: ListFilter<SaleVehicle>) => {
        const key: keyof SaleVehicle = filter.key
        const value: SaleVehicle[keyof SaleVehicle] = vehicle[key] as SaleVehicle[keyof SaleVehicle]
        switch (filter.operator) {
          case 'eq':
            return value === filter.value
          case 'ne':
            return value !== filter.value
          case 'contains':
            return String(value).includes(filter.value)
          case 'ncontains':
            return !String(value).includes(filter.value)
          case 'gt':
            return Number(value) > filter.value
          case 'lt':
            return Number(value) < filter.value
          case 'gte':
            return Number(value) >= filter.value
          case 'lte':
            return Number(value) <= filter.value
          case 'between':
            return Number(value) >= filter.value[0] && Number(value) <= filter.value[1]
          case 'exists':
            return value !== undefined
          case 'in':
            return filter.value.includes(value)
          case 'nin':
            return !filter.value.includes(value)

          default:
            return false
        }
      })
    })
  }

  const filters = preferences.preferences.lists.vehicles.filters
  const displayFilters = filters.map((filter) => filter.key + ' - ' + filter.operator + ' ' + filter.value)
  const removeFilter = (key: string) => {
    preferences.setPreference(
      'lists.vehicles.filters',
      filters.filter((filter) => filter.key !== key)
    )
  }

  const Toolbar = memo(() => <SalesTableHeader store={store} />)

  return (
    <div className='absolute inset-0 top-9 bottom-10'>
      <div className='p-2 text-xs'>
        Sales Filters:
        {displayFilters.map((filter, index) => {
          return (
            <Badge
              key={index}
              className='ml-2 font-normal cursor-pointer hover:underline'
              variant='secondary'
              onClick={() => removeFilter(filter.split(' - ')[0])}
            >
              {filter}
            </Badge>
          )
        })}
        {displayFilters.length === 0 && (
          <Badge className='ml-2 font-normal' variant='secondary'>
            None
          </Badge>
        )}
      </div>
      <TooltipProvider>
        <DataGridPro
          rows={getDataGridRows(filteredDocuments as SaleVehicle[])}
          // @ts-ignore
          columns={getDataGridColumns()}
          loading={sales.loading}
          getRowClassName={getDataGridRowClassnames}
          getCellClassName={(params) => getDataGridCellClassnames(params, store.TargetMargin)}
          density='compact'
          slots={{
            toolbar: Toolbar,
            footer: Footer
          }}
          disableRowSelectionOnClick
        />
      </TooltipProvider>
    </div>
  )
}

var Footer = () => {
  const apiRef = useGridApiContext()
  const visibleRows = gridFilteredSortedRowEntriesSelector(apiRef)
  const totalRows = apiRef.current.getRowsCount()

  const totalGross = visibleRows.reduce((acc, row) => acc + Number(row.model.Gross.replace(/[^0-9.-]+/g, '')), 0)
  const avgGross = totalGross / visibleRows.length
  const avgDaysInInventory =
    visibleRows
      .filter((row) => typeof row.model.DaysInInventory === 'number')
      .reduce((acc, row) => {
        return acc + row.model.DaysInInventory
      }, 0) / visibleRows.filter((row) => typeof row.model.DaysInInventory === 'number').length
  return (
    <div className='flex justify-end gap-2 items-center p-2 border-t'>
      <div>
        Avg days in inventory: <span className='font-bold'>{Math.round(avgDaysInInventory).toLocaleString()}</span> days
      </div>
      <Separator orientation='vertical' className='bg-black/50' />
      <div>
        Total gross: <span className='font-bold'>${Math.round(totalGross).toLocaleString()}</span>
      </div>
      <Separator orientation='vertical' className='bg-black/50' />
      <div>
        Avg gross: <span className='font-bold'>${Math.round(avgGross).toLocaleString()}</span>
      </div>
      <Separator orientation='vertical' className='bg-black/50' />
      <div>
        Showing{' '}
        <span className='font-bold'>
          {visibleRows.length} / {totalRows}
        </span>{' '}
        results
      </div>
    </div>
  )
}
