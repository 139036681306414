import useLocalAppPreferences from '@/hooks/use-local-app-preferences'
import { getInventoryFootprint } from '@/services/api/v2/companies/[companyId]/analytics'
import { useQuery } from '@tanstack/react-query'

export default function useInventoryFootprint(store: Company | undefined, options?: { timeSpan?: string }) {
  const preferences = useLocalAppPreferences()
  const excludeSourceIds = preferences.preferences.dashboard.excludeSources

  const { isPending, data } = useQuery({
    queryKey: ['inventory-footprint', store, excludeSourceIds.join(','), { options }],
    queryFn: async () => await getInventoryFootprint(store?.CompanyId ?? 0, options)
  })

  return { loading: isPending, data: data }
}
