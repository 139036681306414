import LinkWithQuery from '@/components/link-with-query'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui'
import useLocalAppPreferences from '@/hooks/use-local-app-preferences'
import { InventoryOverviewData } from '@/services/api/v2/companies/[companyId]/analytics/inventory-overview'
import { LinearProgress, Skeleton } from '@mui/material'
import { DollarSign, TrendingUp } from 'lucide-react'
import { useState } from 'react'

type BySourceTableProps = {
  data: InventoryOverviewData[] | undefined
  loading: boolean
}

enum ShowTable {
  ALL,
  TOP5
}

export default function BySourceTable({ data, loading }: BySourceTableProps) {
  const [displayType, setDisplayType] = useState<ShowTable>(ShowTable.TOP5)
  const preferences = useLocalAppPreferences()
  return (
    <TooltipProvider delayDuration={0}>
      <Table className='whitespace-nowrap'>
        <TableHeader>
          <TableRow>
            <TableHead className='w-1/5'>Source</TableHead>
            <TableHead className='w-1/5'>Store</TableHead>
            <TableHead className='w-1/5'>Total units</TableHead>
            <TableHead className='w-1/5'>Avg Investment</TableHead>
            <TableHead className='w-1/5'></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data?.[0]?.InventoryBySourceGraphData.slice(
            0,
            displayType === ShowTable.ALL ? data?.[0]?.InventoryBySourceGraphData.length : 5
          ).map((listItem, index) => (
            <TableRow>
              {loading ? (
                <TableCell className='w-1/5'>
                  <div className='flex items-center'>
                    <div className='w-2 h-2 rounded-full inline-block mr-1 -translate-y-[0.05rem] bg-stone-400 animate-pulse'></div>
                    <Skeleton width='80%' height={20} className='inline-block' />
                  </div>
                </TableCell>
              ) : (
                <TableCell className='w-1/5'>
                  <LinkWithQuery
                    to='/sales'
                    onClick={() => preferences.setPreference('lists.vehicles.filters', listItem.appliedFilters)}
                    className='hover:underline'
                  >
                    <span>{listItem.label}</span>
                  </LinkWithQuery>
                </TableCell>
              )}
              <TableCell colSpan={4} className='w-4/5'>
                <Table className='-ml-2'>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell className='w-full'>
                          <Skeleton width='100%' height={20} />
                        </TableCell>
                      </TableRow>
                    ) : (
                      data?.map((item: InventoryOverviewData, storeIndex) => (
                        <TableRow key={`name-${item.Store}`}>
                          <TableCell className='block text-ellipsis overflow-x-hidden w-11/12'>
                            <LinkWithQuery
                              query={{ tabIndex: String(storeIndex) }}
                              to='/sales'
                              onClick={() =>
                                preferences.setPreference('lists.vehicles.filters', listItem.appliedFilters)
                              }
                              className='hover:underline'
                            >
                              <span>{item.Store}</span>
                            </LinkWithQuery>
                          </TableCell>
                          <TableCell className='w-1/4'>
                            <div className='flex items-center gap-2'>
                              {item.InventoryBySourceGraphData[index]?.IsHighVolume && (
                                <Tooltip>
                                  <TooltipTrigger>
                                    <TrendingUp className='stroke-green-600' size={16} />
                                  </TooltipTrigger>
                                  <TooltipContent sideOffset={5} align='center' side='top'>
                                    You have a significant amount of cars from {listItem.label} compared to other
                                    sources
                                  </TooltipContent>
                                </Tooltip>
                              )}
                              <span>
                                {item.InventoryBySourceGraphData[index]?.Count} (
                                {item.InventoryBySourceGraphData[index]?.PercentOfTotal}
                                %)
                              </span>
                            </div>
                          </TableCell>
                          <TableCell className='w-1/4'>
                            <div className='flex items-center gap-2'>
                              {item.InventoryBySourceGraphData[index]?.IsHighInvestment && (
                                <Tooltip>
                                  <TooltipTrigger>
                                    <DollarSign className='stroke-red-600' size={16} />
                                  </TooltipTrigger>
                                  <TooltipContent sideOffset={5} align='center' side='top'>
                                    {listItem.label} averages a substantially higher investment cost than normal
                                  </TooltipContent>
                                </Tooltip>
                              )}
                              {item.InventoryBySourceGraphData[index]?.IsLowInvestment && (
                                <Tooltip>
                                  <TooltipTrigger>
                                    <DollarSign className='stroke-green-600' size={16} />
                                  </TooltipTrigger>
                                  <TooltipContent sideOffset={5} align='center' side='top'>
                                    {listItem.label} averages a particularly low investment cost
                                  </TooltipContent>
                                </Tooltip>
                              )}
                              <span>
                                ${item.InventoryBySourceGraphData[index]?.AverageInvestment?.toLocaleString()}
                              </span>
                            </div>
                          </TableCell>
                          <TableCell className='w-1/4'>
                            <div className='w-full'>
                              <LinearProgress
                                // get the largest
                                value={
                                  (item.InventoryBySourceGraphData[index]?.Count /
                                    Math.max(
                                      item.InventoryBySourceGraphData.reduce(
                                        (acc, curr) => Math.max(acc, curr.Count),
                                        0
                                      ),
                                      1
                                    )) *
                                  100
                                }
                                variant='determinate'
                                className='w-full rounded-lg'
                                sx={{
                                  height: 8,
                                  backgroundColor: 'hsl(var(--border))',
                                  '& .MuiLinearProgress-bar': {
                                    borderRadius: '3px',
                                    backgroundColor: 'hsl(var(--primary))'
                                  }
                                }}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={5} className='text-right'>
              <Button
                variant='secondary'
                className='w-full'
                onClick={() => setDisplayType(displayType === ShowTable.ALL ? ShowTable.TOP5 : ShowTable.ALL)}
              >
                {displayType === ShowTable.ALL
                  ? 'Show Top 5'
                  : `Show All (${(data?.[0]?.InventoryBySourceGraphData.length ?? 5) - 5} more)`}
              </Button>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TooltipProvider>
  )
}
