import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui'
import { InventoryOverviewData } from '@/services/api/v2/companies/[companyId]/analytics/inventory-overview'
import { Skeleton } from '@mui/material'

type CargurusTableProps = {
  data: InventoryOverviewData[] | undefined
  loading: boolean
}

export default function CargurusTable({ data, loading }: CargurusTableProps) {
  const cargurusList: [number, string][] = [
    [0, 'Great Price'],
    [1, 'Good Price'],
    [2, 'Fair Price'],
    [3, 'High Price'],
    [4, 'Overpriced']
  ]
  return (
    <Table className='whitespace-nowrap'>
      <TableHeader>
        <TableRow>
          <TableHead className='w-1/5'>Rating</TableHead>
          <TableHead className='w-1/5'>Store</TableHead>
          <TableHead className='w-1/5'>Total units</TableHead>
          <TableHead className='w-1/5'>Avg age</TableHead>
          <TableHead className='w-1/5'>Avg investment</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {cargurusList.map((listItem: [number, string]) => (
          <TableRow>
            {loading ? (
              <TableCell className='w-1/5'>
                <div className='flex items-center'>
                  <div className='w-2 h-2 rounded-full inline-block mr-1 -translate-y-[0.05rem] bg-stone-400 animate-pulse'></div>
                  <Skeleton width='80%' height={20} className='inline-block' />
                </div>
              </TableCell>
            ) : (
              <TableCell className='w-1/5'>
                <div
                  className='w-2 h-2 rounded-full inline-block mr-1 -translate-y-[0.05rem]'
                  style={{ backgroundColor: data?.[0]?.CargurusGroupings[listItem[0]].Color }}
                ></div>
                <span>{listItem[1]}</span>
              </TableCell>
            )}
            <TableCell colSpan={4} className='w-4/5'>
              <Table className='-ml-2'>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell className='w-full'>
                        <Skeleton width='100%' height={20} />
                      </TableCell>
                    </TableRow>
                  ) : (
                    data?.map((item: InventoryOverviewData) => (
                      <TableRow key={`name-${item.Store}`}>
                        <TableCell className='block text-ellipsis overflow-x-hidden w-11/12'>
                          <span>{item.Store}</span>
                        </TableCell>
                        <TableCell className='w-1/4'>
                          <span>
                            {item.CargurusGroupings[listItem[0]]?.Count} (
                            {item.CargurusGroupings[listItem[0]]?.PercentOfTotal}%)
                          </span>
                        </TableCell>
                        <TableCell className='w-1/4'>
                          <span>{item.CargurusGroupings[listItem[0]]?.AverageDaysInInventory} Days</span>
                        </TableCell>
                        <TableCell className='w-1/4'>
                          <span>${item.CargurusGroupings[listItem[0]]?.AverageInvestment?.toLocaleString()}</span>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
