import { InventoryOverviewData } from '@/services/api/v2/companies/[companyId]/analytics/inventory-overview'
import {
  DataGridPro,
  DataGridProProps,
  GridRenderCellParams,
  GridRowClassNameParams,
  GridRowModel
} from '@mui/x-data-grid-pro'
import { useCallback, useMemo } from 'react'

type ByMakeTableProps = {
  data: InventoryOverviewData[] | undefined
  loading: boolean
}

export default function ByMakeTable({ data, loading }: ByMakeTableProps) {
  const getTreeDataPath: DataGridProProps['getTreeDataPath'] = useCallback((row: GridRowModel) => row.path, [])

  const columns = useMemo(
    () => [
      { field: 'TotalUnits', headerName: 'Units in inventory', flex: 1 },
      {
        field: 'PercentOfTotal',
        headerName: '% of Total',
        flex: 1,
        renderCell: (params: GridRenderCellParams) => `${params.value ?? 0}%`
      },
      { field: 'AverageDaysInInventory', headerName: 'Avg Days in inventory', flex: 1 },
      {
        field: 'TotalInvestment',
        headerName: 'Total investment',
        flex: 1,
        renderCell: (params: GridRenderCellParams) =>
          `$${params.value.toLocaleString({
            style: 'currency',
            currency: 'USD'
          })}`
      },
      {
        field: 'AverageInvestment',
        headerName: 'Avg investment',
        flex: 1,
        renderCell: (params: GridRenderCellParams) =>
          `$${params.value.toLocaleString({
            style: 'currency',
            currency: 'USD'
          })}`
      }
    ],
    []
  )

  const getRowClassName = useCallback((params: GridRowClassNameParams) => {
    if (params.row.path.length === 1) {
      return 'bg-stone-100'
    }
    return ''
  }, [])

  return (
    <div className='w-full h-[700px]'>
      <DataGridPro
        treeData
        getTreeDataPath={getTreeDataPath}
        rows={data?.[0]?.InventoryByMake || []}
        columns={columns}
        loading={loading}
        getRowId={(row) => row.id}
        getRowClassName={getRowClassName}
        disableRowSelectionOnClick
      />
    </div>
  )
}
