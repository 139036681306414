import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui'
import useStores from '@/hooks/use-stores'
import useUser from '@/hooks/use-user'
import DashboardHeader from './components/dashboard-header'
import useLocalAppPreferences from '@/hooks/use-local-app-preferences'
import { useEffect, useState } from 'react'
import EditPreferences from './components/edit-preferences'
import SalesOverviewTab from './components/tab-sales-overview'
import InventoryOverviewTab from './components/tab-inventory-overview'
import OverviewTab from './components/overview.tab'
import InventoryFootprintTab from './components/inventory-footprint.tab'

export default function Dashboard() {
  const user = useUser()
  const stores = useStores()
  const preferences = useLocalAppPreferences()
  const defaultTab = preferences.preferences.dashboard.defaultTab ?? 'overview'
  const [storeTab, setStoreTab] = useState<number>(0)
  useEffect(() => {
    setStoreTab(stores?.data?.filter((store) => store.IsSelected)[0]?.CompanyId ?? 0)
  }, [stores.data])

  return (
    <main
      className='flex justify-center items-start p-2 md:p-4 z-10 h-full container'
      style={{
        paddingTop: 0
      }}
    >
      <Tabs
        value={defaultTab}
        className='w-full max-w-[72rem]'
        onValueChange={(newValue) => preferences.setPreference('dashboard.defaultTab', newValue)}
      >
        <TabsList className='rounded-t-none p-0 h-8 bg-background'>
          <TabsTrigger
            className='px-2 h-full w-full rounded-t-none data-[state=active]:shadow-none bg-input mr-0.5'
            value='overview'
          >
            Overview
          </TabsTrigger>
          <TabsTrigger
            className='px-2 h-full w-full rounded-t-none data-[state=active]:shadow-none bg-input mr-0.5'
            value='inventory-breakdown'
          >
            Inventory breakdown
          </TabsTrigger>
          <TabsTrigger
            className='px-2 h-full w-full rounded-t-none data-[state=active]:shadow-none bg-input mr-0.5'
            value='sales-breakdown'
          >
            Sales breakdown
          </TabsTrigger>
          <TabsTrigger
            className='px-2 h-full w-full rounded-t-none data-[state=active]:shadow-none bg-input mr-0.5'
            value='inventory-footprint'
          >
            Inventory footprint
          </TabsTrigger>
        </TabsList>
        <div className='my-6'>
          <DashboardHeader user={user} />
        </div>
        <TabsContent value='overview' className='w-full'>
          <OverviewTab stores={stores} />
        </TabsContent>
        <TabsContent value='sales-breakdown' className='w-full'>
          <SalesOverviewTab stores={stores} />
        </TabsContent>
        <TabsContent value='inventory-breakdown' className='w-full'>
          <InventoryOverviewTab stores={stores} />
        </TabsContent>
        <TabsContent value='inventory-footprint' className='w-full absolute inset-0 top-24'>
          <Tabs value={String(storeTab)} onValueChange={(newValue) => setStoreTab(Number(newValue))}>
            <div className='flex gap-2 justify-between items-center'>
              <TabsList className='rounded-b-none'>
                {stores?.data
                  .filter((store) => store.IsSelected)
                  .map((store) => (
                    <TabsTrigger key={store.CompanyId} value={String(store.CompanyId)} className='rounded-b-none'>
                      {store.Name}
                    </TabsTrigger>
                  ))}
              </TabsList>
              <EditPreferences store={stores?.data.find((store) => store.CompanyId === storeTab)} />
            </div>
            {storeTab && <InventoryFootprintTab store={stores?.data.find((store) => store.CompanyId === storeTab)} />}
          </Tabs>
        </TabsContent>
      </Tabs>
    </main>
  )
}
