import { AnimatePresence, motion } from 'framer-motion'
import DataCard from '../data-card'
import SectionCard from '../section-card'
import { Card, CardContent, CardHeader, CardTitle, TooltipProvider } from '@/components/ui'
import { InventoryOverviewData } from '@/services/api/v2/companies/[companyId]/analytics/inventory-overview'
import { Skeleton } from '@mui/material'

type InventoryDataSectionProps = {
  data: InventoryOverviewData[] | undefined
  loading: boolean
  excludeTiles?: (
    | 'TotalInventory'
    | 'TotalInvestment'
    | 'AverageInvestment'
    | 'DaysSupply'
    | 'AverageAge'
    | 'FranchiseUnitRatio'
  )[]
}

export default function InventoryDataSection({ data, loading, excludeTiles = [] }: InventoryDataSectionProps) {
  return (
    <SectionCard title='Inventory Overview' description={`All current inventory data`}>
      <TooltipProvider>
        <div className='w-full grid lg:flex grid-cols-3 gap-2 md:gap-4 p-0 pb-1'>
          {!excludeTiles.includes('TotalInventory') && (
            <DataCard
              title='Total inventory'
              data={data}
              dataKey='TotalInventory'
              type='number'
              loading={loading}
              color='primary'
            />
          )}
          {!excludeTiles.includes('TotalInvestment') && (
            <DataCard
              title='Total investment'
              data={data}
              dataKey='TotalInvestment'
              type='currency'
              loading={loading}
            />
          )}
          {!excludeTiles.includes('AverageInvestment') && (
            <DataCard
              title='Avg investment'
              data={data}
              dataKey='AverageInvestment'
              type='currency'
              loading={loading}
            />
          )}
          {!excludeTiles.includes('DaysSupply') && (
            <DataCard
              title='Days supply'
              data={data}
              dataKey='DaysSupply'
              type='number'
              loading={loading}
              explanation='Days supply in inventory based on the last 30 days of sales'
            />
          )}
          {!excludeTiles.includes('AverageAge') && (
            <DataCard title='Avg age' data={data} dataKey='AverageAge' type='number' loading={loading} />
          )}
          {!excludeTiles.includes('FranchiseUnitRatio') && (
            <Card className='w-full md:min-w-32'>
              <CardHeader className='text-sm p-2 md:p-4 md:pb-2 space-y-0'>
                <CardTitle className='font-normal whitespace-nowrap flex items-center gap-1'>
                  Franchise | Other
                </CardTitle>
              </CardHeader>
              <CardContent className='text-base font-bold p-2 md:p-4 md:pb-2 pt-0 md:pt-0'>
                <AnimatePresence mode='popLayout'>
                  {loading === true || !data ? (
                    <motion.div
                      key='data-loading'
                      initial={{ width: '0%', paddingLeft: 0 }}
                      animate={{ width: '100%', paddingLeft: 0, opacity: 1 }}
                      exit={{ width: '0%', paddingLeft: '100%', opacity: 0, transition: { duration: 0.3 } }}
                      className='bg-background'
                      transition={{ duration: 0.1, type: 'tween', ease: 'easeInOut' }}
                    >
                      <Skeleton variant='text' width='100%' height={16} />
                      <Skeleton variant='text' width='100%' height={24} />
                    </motion.div>
                  ) : (
                    <div key={`data-loaded__${Boolean(loading).toString()}`} className='flex flex-col gap-2'>
                      {data?.map((item) => {
                        return (
                          <div className='flex flex-col gap-0' key={item.Store}>
                            <span className='text-xs font-normal opacity-60'>{item.Store}</span>
                            <span className='text-base font-bold flex items-center gap-2'>
                              {item.FranchiseUnitCount} | {item.NonFranchiseUnitCount}
                            </span>
                          </div>
                        )
                      })}
                    </div>
                  )}
                </AnimatePresence>
              </CardContent>
            </Card>
          )}
        </div>
      </TooltipProvider>
    </SectionCard>
  )
}
