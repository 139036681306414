import useStores from '@/hooks/use-stores'
import { Tabs, TabsList, TabsTrigger } from './ui'

type StoreTabsProps = {
  children: React.ReactNode | React.ReactNode[]
}

export default function StoreTabs({ children }: StoreTabsProps) {
  const stores = useStores()
  return (
    <Tabs defaultValue='0'>
      <TabsList>
        {stores?.data
          .filter((e) => e.IsSelected)
          .map((store, index) => (
            <TabsTrigger key={store.CompanyId} value={String(index)}>
              {store.Name}
            </TabsTrigger>
          ))}
      </TabsList>
      {children}
    </Tabs>
  )
}
