import { Button } from '@/components/ui'
import { v2 as axios } from '@/lib/axios'
import { useMutation, useQuery } from '@tanstack/react-query'

type DetailsPanelContentProps = {
  row: ReportHistory
}

export default function DetailPanelContent({ row }: DetailsPanelContentProps) {
  const {
    data: content,
    isPending: loading,
    refetch
  } = useQuery({
    queryKey: ['report-history', row.ReportHistoryId],
    queryFn: async () => {
      const { data } = await axios.get(row.ReportContentUrl)
      const parser = new DOMParser()
      const doc = parser.parseFromString(data, 'text/html')
      const img = doc.querySelector('img')
      const src = img?.getAttribute('src')
      return src
    }
  })

  const { mutateAsync: sendReport, isPending: sending } = useMutation({
    mutationKey: ['send-report'],
    mutationFn: async () => {
      await axios.get(`/companies/${row.CompanyId}/reports/send/${row.ReportHistoryId}`)
      refetch()
      await new Promise((resolve) => setTimeout(resolve, 1000))
      refetch()
    }
  })

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <>
      <Button onClick={() => sendReport()} loading={sending}>
        Send
      </Button>
      <div className=' flex justify-center items-start'>
        <img src={content ?? ''} />
      </div>
    </>
  )
}
