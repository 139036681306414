// <DataCard
// title='Total Sales'
// data={data}
// dataKey='TotalSales'
// type='number'
// loading={loading}
// color='primary'
// comparator
// />
// <DataCard
// title='Avg Sales'
// data={data}
// dataKey='AverageSalesPerDay'
// type='number'
// loading={loading}
// comparator
// />
// <DataCard title='Sales Pace' data={data} dataKey='SalesPace' type='number' loading={loading} comparator />
// <DataCard
// title='Total Gross'
// key={adjustments ? 'TotalGrossWithAdjustments' : 'TotalGross'}
// data={data}
// dataKey={adjustments ? 'TotalGrossWithAdjustments' : 'TotalGross'}
// type='currency'
// loading={loading}
// color='primary'
// comparator
// />
// <DataCard
// title='Avg Gross'
// key={adjustments ? 'AverageGrossPerSaleWithAdjustments' : 'AverageGrossPerSale'}
// data={data}
// dataKey={adjustments ? 'AverageGrossPerSaleWithAdjustments' : 'AverageGrossPerSale'}
// type='currency'
// loading={loading}
// comparator
// />
// <DataCard
// title='Gross Pace'
// key={adjustments ? 'GrossPaceWithAdjustments' : 'GrossPace'}
// data={data}
// dataKey={adjustments ? 'GrossPaceWithAdjustments' : 'GrossPace'}
// type='currency'
// loading={loading}
// comparator
// />

const salesOverviewTiles = [
  {
    title: 'Total Sales',
    dataKey: 'TotalSales',
    type: 'number',
    color: 'primary',
    isComparator: true
  },
  {
    title: 'Avg Sales',
    dataKey: 'AverageSalesPerDay',
    type: 'number',
    isComparator: true
  },
  {
    title: 'Sales Pace',
    dataKey: 'SalesPace',
    type: 'number',
    isComparator: true
  },
  {
    title: 'Total Gross',
    key: 'TotalGross',
    dataKey: 'TotalGross',
    alternateKey: 'TotalGrossWithAdjustments',
    type: 'currency',
    color: 'primary',
    isComparator: true
  },
  {
    title: 'Avg Gross',
    key: 'AverageGrossPerSale',
    dataKey: 'AverageGrossPerSale',
    alternateKey: 'AverageGrossPerSaleWithAdjustments',
    type: 'currency',
    isComparator: true
  },
  {
    title: 'Gross Pace',
    key: 'GrossPace',
    dataKey: 'GrossPace',
    alternateKey: 'GrossPaceWithAdjustments',
    type: 'currency',
    isComparator: true
  }
]

export default {
  salesOverviewTiles
}
