import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui'
import { cn } from '@/lib/utils'

type SectionCardProps = {
  title: string | React.ReactNode
  description: string | React.ReactNode
  className?: string
  children: React.ReactNode
}

export default function SectionCard({ title, description, className, children }: SectionCardProps) {
  return (
    <Card className={cn('w-full', className)}>
      <CardHeader className='p-2 md:p-4 w-full'>
        <CardTitle>{title}</CardTitle>
        {description && <CardDescription>{description}</CardDescription>}
      </CardHeader>
      <CardContent className='p-4 pt-0'>{children}</CardContent>
    </Card>
  )
}
