import SectionCard from '../section-card'
import { Card, CardContent, CardHeader, CardTitle, TooltipProvider } from '@/components/ui'
import CargurusStackedPie from './cargurus-stacked-pie'
import CargurusBar from './cargurus-bar'
import CargurusTable from './cargurus-table'
import { Skeleton } from '@mui/material'
import ByAgeStackedPie from './by-age-stacked-pie'
import ByAgeTable from './by-age-table'
import BySourceTable from './by-source-table'
import useInventoryAnalytics from '../../hooks/use-inventory-analytics'
import InventoryDataSection from './inventory-data.section'
import ByMakeTable from './by-make-table'

type InventoryOverviewTabProps = {
  stores: any
}

export default function InventoryOverviewTab({ stores }: InventoryOverviewTabProps) {
  const { data, loading } = useInventoryAnalytics(stores)
  return (
    <div className='flex flex-col gap-4 pb-32'>
      <TooltipProvider>
        <InventoryDataSection data={data} loading={loading} />
        <SectionCard
          title='Cargurus at a glance'
          description='Cargurus pricing data for all stores'
          className='relative'
        >
          <div className='w-full flex flex-col md:flex-row space-evenly gap-4'>
            <CargurusStackedPie
              title='Inventory'
              data={data}
              loading={loading}
              legendAlign={data?.length && data.length > 1 ? 'center' : 'left'}
              legendPosition={data?.length && data.length > 1 ? 'bottom' : 'middle'}
            />
            <CargurusTable data={data} loading={loading} />
          </div>
          {data?.map((entry, index) => (
            <Card key={index} className='mt-2'>
              <CardHeader className='p-2 pt-4 pl-4'>
                <CardTitle>
                  {loading ? (
                    <Skeleton width='40%' height={20} className='inline-block' />
                  ) : (
                    `Cargurus pricing for ${entry.Store}`
                  )}
                </CardTitle>
              </CardHeader>
              <CardContent className='p-0 px-4 pb-2'>
                <CargurusBar
                  data={entry?.CargurusRating}
                  carGurusPreference={entry?.CargurusPreference}
                  loading={loading || stores.loading}
                />
              </CardContent>
            </Card>
          ))}
        </SectionCard>
        <SectionCard title='Inventory by age' description='Inventory data by age of vehicle'>
          <div className='w-full flex flex-col md:flex-row space-evenly gap-4'>
            <ByAgeStackedPie
              title='Inventory'
              data={data}
              loading={loading}
              legendAlign={data?.length && data.length > 1 ? 'center' : 'left'}
              legendPosition={data?.length && data.length > 1 ? 'bottom' : 'middle'}
            />
            <ByAgeTable data={data} loading={loading} />
          </div>
        </SectionCard>
        <SectionCard title='Inventory by source' description='Inventory data by source of vehicle'>
          <BySourceTable data={data} loading={loading} />
        </SectionCard>
        <SectionCard title='Inventory by make' description='Inventory data by make of vehicle'>
          <ByMakeTable data={data} loading={loading} />
        </SectionCard>
      </TooltipProvider>
    </div>
  )
}
